<template>
  <section class="hero-section" id="hero">
    <div class="text-center hero-pic">
      <div class="mask">
        <div class="d-flex justify-content-center align-items-center h-100">
          <div
            class=""
            v-motion
            :initial="{
              opacity: 0,
              y: 200,
            }"
            :enter="{
              opacity: 1,
              y: 0,
              transition: {
                duration: 2000,
              },
            }"
          >
            <div class="vivlogo">
              <img
                src="../assets/vivlogo2.png"
                alt="vivlogo"
                width="300"
                height="200"
                class="img-fluid"
              />
            </div>
            <div class="">
              <a
                class="btn btn-outline-light btn-lg mt-4 homebutton"
                href="#footer"
                role="button"
                >Request a quote</a
              >
              <div>
                <h3 class="text-white mt-3">
                  Events Planning and Catering Services
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-section" id="about">
    <div class="about-container">
      <div>
        <Carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
          <Slide v-for="aboutSlide in aboutSlides" :key="aboutSlide.id">
            <div class="carousel__item2">
              <div class="caro mt-5">
                <h3>{{ aboutSlide.header }}</h3>

                <div class="container">
                  <p>{{ aboutSlide.content }}</p>
                </div>

                <div class="sliding-icons">
                  <img
                    :src="aboutSlide.url"
                    alt=""
                    class="about-photos img-fluid"
                  />
                </div>
              </div>
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>

        <div class="container-fluid mt-5">
          <div class="collage">
            <img src="../assets/cooking-min.png" alt="Chef" />
            <img src="../assets/decor-min.png" alt="Decor" />
            <img src="../assets/dj-min.png" alt="DJ" />
            <img src="../assets/buffetserve.png" alt="Caterers" />
            <img src="../assets/buffettwo.png" alt="Caterers" />
          </div>
        </div>
        <div class="client-logos mt-5">
          <div class="text-center">
            <h4>Our Clients</h4>
          </div>
          <Carousel
            :items-to-show="5"
            :wrap-around="true"
            :autoplay="3000"
            class="logo-container"
          >
            <Slide v-for="slide in slides" :key="slide.id">
              <div class="carousel__item mt-2">
                <img :src="slide.url" alt="" class="imagery" />
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>
  </section>
  <section class="team-section centering-items container p-4 mt-4" id="team">
    <div class="team-wrapper p-3">
      <div class="row">
        <div class="p-2 mgin col">
          <div class="card centering-items">
            <img
              src="../assets/vivian.png"
              alt="member"
              class="rounded-circle mt-3"
              height="150px"
              width="150px"
            />
            <div class="card-body">
              <h5 class="card-title text-center">VIVIAN - CEO</h5>
              <p class="card-text text-center">
                A passionate and successful businesswoman with an eye for
                offering quality services backed by years of experience.
              </p>
            </div>
          </div>
        </div>
        <div class="col p-2 mgin">
          <div class="card centering-items">
            <img
              src="../assets/el.png"
              alt="member"
              class="rounded-circle mt-3"
              height="150px"
              width="150px"
            />
            <div class="card-body">
              <h5 class="card-title text-center">ELVINCE - CHEF</h5>
              <p class="card-text text-center">
                An experienced chef with an educational background in food
                science. He is a culinery master
              </p>
            </div>
          </div>
        </div>
        <div class="col p-2 mgin">
          <div class="card centering-items">
            <img
              src="../assets/elvince.png"
              alt="member"
              class="rounded-circle mt-3"
              height="150px"
              width="150px"
            />
            <div class="card-body">
              <h5 class="card-title text-center">SMITH - CATERING STEWARD</h5>
              <p class="card-text text-center">
                A team player that works around the clock to make sure client's
                instructions are followed the letter to make the event a success
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";

export default {
  name: "Maincontent",
  components: { Carousel, Pagination, Slide, Navigation },

  data() {
    return {
      slides: [
        { id: "1", url: require("@/assets/vivlogo.png") },
        { id: "2", url: require("@/assets/blueridgelogo.png") },
        { id: "3", url: require("@/assets/kpa.png") },
        { id: "4", url: require("@/assets/kuzalogo.png") },
        { id: "5", url: require("@/assets/unga.png") },
        { id: "6", url: require("@/assets/tupande.png") },
        { id: "7", url: require("@/assets/bloom.png") },
      ],
      aboutSlides: [
        {
          id: "2",
          header: "Who we are",
          url: require("@/assets/team.png"),
          content:
            "We are a team of caterers and event planners with years of experience.",
        },
        {
          id: "3",
          header: "Clientele",
          url: require("@/assets/wed.png"),
          content:
            "Trusted by hundreds of clients to handle events ranging from weddings, birthdays,corporate forums, school events, funerals & many more.",
        },
        {
          id: "3",
          header: "History",
          url: require("@/assets/timeline.png"),
          content:
            "Vivianas Catering was founded in 2017 by Vivian Sarah with an aim to provide affordable catering services in Kisumu. The company has since then grown & worked with clients all over the country",
        },
        {
          id: "3",
          header: "Why us",
          url: require("@/assets/catering.png"),
          content:
            "We provide the most affordble catering rates. We are willing to go the extra mile to make your event a success.",
        },
      ],

      gra: null,
      init: null,
    };
  },
};
</script>

<style scoped>
.custom-color {
  color: rgb(219, 201, 42) !important;
}

.mask {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.mgin {
  margin: 10px;
}

.hero-pic {
  background-image: url("../assets/servers-min.png");
  background-size: cover;
}

.about-section {
  background-color: white;
  min-height: 100vh;
}

.carousel__item {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.imagery {
  height: auto;
  width: 70px;
}

.carousel__item2 {
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-logos {
  margin-top: 2%;
}

.about-photos {
  max-width: 250px;
  height: 250px;
}

.centering-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background-color: rgb(219, 201, 42);
  transition: transform 0.2s;
  height: 370px;
}

.card:hover {
  transform: scale(1.1);
}

.card-title {
  color: white;
  font-weight: 500;
}

.card-text {
  font-weight: 500;
}

.homebutton {
  font-family: "Montserrat", sans-serif;
}

.form-wrapper {
  width: 50%;
}

.borders {
  border: 2px solid green;
}

.btn.btn-outline-light {
  color: rgb(219, 201, 42);
  border-color: rgb(219, 201, 42);
}

.btn.btn-outline-light:hover {
  background-color: rgb(219, 201, 42);
  color: white;
}

.collage {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Adjust minmax values for your needs */
  grid-gap: 10px; /* Adjust the gap between photos */
}

.collage img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 768px) {
  .hero-pic {
    background-image: url("../assets/servers-min.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 78vh;
  }
}
</style>
