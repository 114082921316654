<template>
  <div id="app" class="fade-in-image">
    <Navigation />

    <Maincontent />

    <Whatsapp />


    <Footer />
  </div>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Maincontent from "./components/Maincontent.vue";
import Footer from "./components/Footer.vue";
import Whatsapp from "./components/Whatsapp.vue"
 
export default {
  name: "App",
  components: { Navigation, Maincontent, Footer, Whatsapp },
};
</script>



<style>
#app {
  background-color: rgb(40, 40, 43);
}

h1,
h2,
h3,
h4,
h5,
p,
li,
a {
  font-family: "Montserrat", sans-serif;
}

.fade-in-image {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
