<template>
  <footer id="footer" class="bg-light foot-content">
    <div class="pt-5 mt-5">
      <div class="container">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            <div class="widget-title d-flex justify-content-center align-items-center">
              <h3 class="mb-4 text-secondary text-uppercase">Call Us Today</h3>
            </div>
            <h4 class="text-success d-flex justify-content-center align-items-center">+254-726-250-134</h4>
            <p class="text-secondary m-0 mt-3 d-flex justify-content-center align-items-center">
              Mon. - Fri. <b>8:00 AM - 7:00 PM</b>
            </p>
            <p class="text-secondary d-flex justify-content-center align-items-center">Sat. - Sun. <b>Flexible Hours</b></p>
          </div>
          <div class="col-sm-6 col-md-6 mb-3">
            <div class="success centering-items" v-if="sentEmail">
              <span>EMAIL SENT</span>
            </div>
            <form @submit.prevent="sendEmail" v-if="notSent">
              <div class="card rounded-0">
                <div class="card-header p-0">
                  <div class="text-white text-center py-2 quote-header">
                    <h3>Request Quote</h3>
                  </div>
                </div>
                <div class="card-body p-3">
                  <!--Body-->
                  <div class="form-group">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        class="form-control"
                        id="names"
                        name="name"
                        placeholder="Enter Full Names"
                        required
                        v-model="name"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group mb-2">
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter Email Address"
                        required
                        v-model="email"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group mb-2">
                      <input
                        type="text"
                        class="form-control"
                        id="number"
                        name="number"
                        placeholder="Enter Phone Number"
                        required
                        v-model="phone"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group mb-2">
                      <textarea
                        class="form-control"
                        placeholder="Briefly describe what you need "
                        required
                        name="message"
                        v-model="message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button class="btn btn-dark mx-5 mb-2">Send Email</button>
              </div>
            </form>
          </div>
          <div class="col-sm-6 col-md-3">
            <iframe
              class="w-100 maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255348.15986362836!2d34.59807888807205!3d-0.07394211911515454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182aa496ab60aebd%3A0x71c2d31867dd2a11!2sKisumu%20City%20Business%20Park!5e0!3m2!1sen!2ske!4v1669022480458!5m2!1sen!2ske"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              height="250px"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
        
  </footer>
  <div class="container-fluid bottom-content">
        <p class="text-center text-white mt-3">
         © {{ currentYear }} Copyright: Viviana's Catering Services
        </p>
      </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "contact",
  data() {
    return {
      name: "",
      email: "",
      message: "",
      phone:"",
      sentEmail: false,
      notSent: true,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    sendEmail(e) {
      emailjs.sendForm(
        "service_p9hjj89",
        "template_kxgbtnn",
        e.target,
        "Sj662AO5ZVrD37wJ4",
        {
          name: this.name,
          email: this.email,
          message: this.message,
          number:this.phone
        }
      ),
        // Reset form field
        (this.name = "");
      this.email = "";
      this.message = "";
      this.phone ="";
      this.sentEmail = true;
      this.notSent = false;
    },
  },
};
</script>

<style scoped>
.centering-items {
  display: flex;
  justify-content: center;
  align-items: center;
}


.quote-header {
  background-color: rgb(40, 40, 43);
}

.bottom-content {
  background-color: rgb(40, 40, 43);
  height: 9vh;

}

.btn {
  font-family: "Montserrat", sans-serif;
}

.btn:hover {
  transform: scale(1.05);
}

.borders {
  border: 2px solid green;
}

span {
  color: green;
  font-weight: bolder;
  font-size: 50px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}
</style>