<template>
  <nav
    class="navbar fixed-top navbar-expand-lg navbar-dark p-md-1"
    id="navigation"
  >
    <div class="container">
      <a class="navbar-brand" href="#">Viviana's <span>Catering</span></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
       
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <div class="mx-auto"></div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#hero" >Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about" >About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#team" >The Team</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#footer" >Pricing</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {


  mounted() {
    window.addEventListener("scroll", function () {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        document.getElementById("navigation").style.background =
          "rgb(40, 40, 43)";
      } else {
        document.getElementById("navigation").style.background = "";
      }
    });
    
  },
};
</script>

<style scoped>
.nav-link {
  color: white;
}

.nav-link:hover {
  color: rgb(219, 201, 42);
}

span {
  color: rgb(219, 201, 42);
  font-family: "Montserrat", sans-serif;
}
</style>